import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export default function AboutUs() {
  return (
    <div className="bg-white ">
      <section className="relative isolate overflow-hidden bg-white px-6 pt-10 sm:pt-16 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-4xl">
          <figure>
            <blockquote className="font-base text-center text-xl leading-8 text-gray-900 sm:text-3xl sm:leading-9 ">
              <p>"Design adds value faster than it adds costs."</p>
              <p> -- Joel Spolsky</p>
            </blockquote>
          </figure>
        </div>
      </section>
      <div className="homepage-template">
        <div className="flex w-full flex-col md:w-8/12 md:pr-12  lg:w-7/12 xl:w-9/12">
          <h1 className="page-title">WELCOME</h1>
          <p className="page-text">
            Welcome to Steele Design Co. Our full-service interior design studio
            is dedicated to bringing your dream home to life through renovation,
            remodels, new builds, and interior decorating services. From concept
            to completion, we’ll guide you through the design process and create
            a home that reflects your unique style and lifestyle.
          </p>
          <p className="page-text">
            Steele Design Co. is known for creating interior spaces which are
            one-of-a-kind, stylish, highly functional and timeless, where people
            can be at ease and delight in their surroundings for years to come.
            We understand that a well-designed home improves all aspects of your
            life. Ultimately, we’re people pleasers and flexible in how we can
            work together. Live your best life in a well-designed home – today!{' '}
          </p>
          <p className="page-text">
            Suzanne Steele is the proud owner and principle designer of Steele
            Design Co. Based in London, Ontario, and boasting more than 20 years
            experience in the commercial and residential design industry.{' '}
          </p>
          <p className="page-text">
            In 1997, Suzanne graduated from Toronto Metropolitan University
            (Formerly Ryerson University) earning a Bachelor of Applied Arts in
            Interior Design. This program is one of the top ranking Interior
            Design Programs around the world. For the last three decades, she
            has worked within the interior design industry and built an
            impressive skill set and contact list.
          </p>
          <p className="page-text !text-accent">
            Not sure if we’re the right fit? We offer a 15 minute complimentary
            discovery call and info session – give us a call today!
          </p>
        </div>
        <div className="mx-auto mt-10 flex w-full flex-col items-center justify-center md:mt-0  md:w-6/12 lg:w-5/12 xl:w-4/12">
          <div className="relative">
            <StaticImage
              src="../images/lifestyle-1.jpg"
              alt="magazine article screenshote page 1"
            />
          </div>
          <div className="relative mt-12">
            <StaticImage
              src="../images/lifestyle-2.jpg"
              alt="magazine article screenshote page 2"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
