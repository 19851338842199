import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Seo from '../components/seo';
import AboutUs from '../components/aboutUs';

function Index() {
  return (
    <Layout>
      <Seo title="Full-Service Interior Design Studio" />
      <StaticImage
        src="../images/hero-image.jpg"
        alt="Interior Design"
        loading="eager"
        className="lg:max-h-[32rem]"
      />

      <AboutUs />
    </Layout>
  );
}

export default Index;
